let url = 'http://evideoprime.tech-solutions.co.in'
export const API_ADDRESS = url + '/api';
export const WEB_ADDRESS = url;
export const IMAGE_ADDRESS = url;
/*export const API_ADDRESS = 'http://localhost:2020/api';
export const WEB_ADDRESS = 'https://localhost:2000';
export const IMAGE_ADDRESS = 'http://localhost:2020';*/
export const CART_ID = 'cid';
export const RECEIPT_ID = 'rid';
export const AUTH_ID = 'aid';
export const ENCKEY = 'Logiverse@eVideo123';
export const RAZORPAY = {
    key: "rzp_test_iS4ZXWqjE5NVAq"
};
export const TAX = {
    GST: 18
};